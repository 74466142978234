<template>
  <v-footer
    tile
    class="font-weight-medium mt-4 mb-1"
    :dark="$vuetify.theme.dark"
    :inset="true"
  >
    <v-row class="pa-0 pb-0 text-center d-block d-lg-flex d-sm-flex font-weight-medium my-2" align="center" justify="center">
      <div class="mr-1 mb-3 mb-sm-1 mb-lg-0">© Positive Media Ltd {{ new Date().getFullYear() }}.</div>
      <div class="mr-1 mb-3 mb-sm-1 mb-lg-0">All rights reserved | Privacy Policy</div>
      <div>Produced by The BE IT SAFE GROUP</div>
    </v-row>
  </v-footer>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "FooterLayout",
  computed: {
    ...mapGetters(["getThemeMode"]),
  },
};
</script>
